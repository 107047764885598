



export const changeLocale = (locale) => dispatch => {
    localStorage.setItem('currentLanguage', locale);
	dispatch({
		type: 'CHANGE_LOCALE',
		payload: locale
	})
}


export const setOrderFilter = (orderFilter) => dispatch => {
	dispatch({
		type: 'CHANGE_ORDER_FILTER',
		payload: orderFilter
	})
}
export const setOfferFilter = (offerFilter) => dispatch => {
	dispatch({
		type: 'CHANGE_OFFER_FILTER',
		payload: offerFilter
	})
}
export const setOrderItemFilter = (orderItemFilter) => dispatch => {
	dispatch({
		type: 'CHANGE_OFFERITEM_FILTER',
		payload: orderItemFilter
	})
}
export const setOperationFilter = (operationFilter) => dispatch => {
	dispatch({
		type: 'CHANGE_OPERATION_FILTER',
		payload: operationFilter
	})
}
export const setOrderCount = (orderCount) => dispatch => {

	dispatch({
		type: 'SET_ORDER_COUNT',
		payload: orderCount
	})
}
export const setOfferCount = (offerCount) => dispatch => {
	dispatch({
		type: 'SET_OFFER_COUNT',
		payload: offerCount
	})
}
export const setOperationCount = (operationCount) => dispatch => {
	dispatch({
		type: 'CHANGE_OPERATION_COUNT',
		payload: operationCount
	})
}
export const setOrderItemCount = (orderItemCount) => dispatch => {
	dispatch({
		type: 'SET_ORDERITEM_COUNT',
		payload: orderItemCount
	})
}
export const setOperationComplatedFilter = (operationComplatedFilterValue) => dispatch => {
	dispatch({
		type: 'SET_OPERATIONCOMPLATED_FILTER',
		payload: operationComplatedFilterValue
	})
}
export const copyProductSpecs = (copy,copyForm) => dispatch => {
	dispatch({
		type: 'COPY_PRODUCT_SPECS',
		payload: copy,
		form:copyForm
	})
}

export const setProductsPerVal = (filter) => dispatch => {
	dispatch({
		type: 'SET_PRODUCTLIST_PERVAL',
		payload: filter,
	})
}
export const  setProductsFilter = (val) => dispatch => {
	dispatch({
		type: 'SET_PRODUCTLIST_FILTER',
		payload: val,
	})
}

export const setPriceListText = (text) => dispatch => {
	dispatch({
		type: 'SET_PRICELIST_TEXT',
		payload: text,
	})
}
export const  setPriceListType = (val) => dispatch => {
	dispatch({
		type: 'SET_PRICELIST_TYPE',
		payload: val,
	})
}
export const  setPriceListTextOpen = (val) => dispatch => {
	dispatch({
		type: 'SET_PRICELISTTEXTOPEN',
		payload: val,
	})
}
export const  setLabelData = (val) => dispatch => {
	dispatch({
		type: 'SET_LABELDATA',
		payload: val,
	})
}
export const  setInvoiceType = (val) => dispatch => {
	dispatch({
		type: 'SET_OFFER_TYPE',
		payload: val,
	})
}
export const  setCalculateData = (val) => dispatch => {
	dispatch({
		type: 'SET_CALCULATE_PRODUCT',
		payload: val,
	})
}