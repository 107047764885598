
const initialState = {

	orderFilter: 1,
	offerFilter: 0,
	orderItemFilter:1,
	operationFilter:"Tümü",
	orderCount:0,
	orderItemCount:0,
	offerCount:0,
	operationCount:0,
	operationComplatedFilter:false,
	productsFilter:0,
	listPerVal:30,
	priceListTextOpen:true,
	pricelisttext:`Değerli Müşterimiz, Ürünlerimize ait fiyat  listesi  aşağıda tarafınıza sunulmuştur. Fiyat listemiz üzerinden %XX iskonto  uygulamaktayız. Bilgilerinize sunar iyi çalışmalar dileriz.`,
	pricelisttype:0
,	copy:[],
	copyForm:"",
	LabelData:"",
	locale:"tr",
	invoiceType:0,
	calculateRate:false
}
const settings = (state = initialState, action) => {
	switch (action.type) {
		case 'CHANGE_LOCALE':
			return { ...state, locale:action.payload};
		case 'CHANGE_ORDER_FILTER':
			return { ...state, orderFilter:action.payload};
		case 'CHANGE_OFFER_FILTER':
			return { ...state, offerFilter:action.payload};	
		case 'CHANGE_OFFERITEM_FILTER':
			return { ...state, orderItemFilter:action.payload};	
		case 'CHANGE_OPERATION_FILTER':
			return { ...state, operationFilter:action.payload};	
		case 'SET_ORDER_COUNT':
			return { ...state, orderCount:action.payload};	
		case 'SET_OFFER_COUNT':
			return { ...state, offerCount:action.payload};	
		case 'CHANGE_OPERATION_COUNT':
			return { ...state, operationCount:action.payload};	
		case 'SET_ORDERITEM_COUNT':
			return { ...state, orderItemCount:action.payload};	
		case 'SET_OPERATIONCOMPLATED_FILTER':
			return { ...state, operationComplatedFilterValue:action.payload};
		case 'COPY_PRODUCT_SPECS':
			return { ...state, copy:action.payload, copyForm:action.form}; 
		case 'SET_PRODUCTLIST_PERVAL':
			return { ...state, listPerVal:action.payload}; 
		case 'SET_PRODUCTLIST_FILTER':
			return { ...state, productsFilter:action.payload}; 
		case 'SET_PRICELIST_TEXT':
			return { ...state, pricelisttext:action.payload}; 
		case 'SET_PRICELIST_TYPE':
			return { ...state, pricelisttype:action.payload}; 
		case 'SET_PRICELISTTEXTOPEN':
			return { ...state, priceListTextOpen:action.payload}; 
		case 'SET_LABELDATA':
			return { ...state, LabelData:action.payload}; 
		case 'SET_OFFER_TYPE':
			return { ...state, invoiceType:action.payload}; 
		case 'SET_CALCULATE_PRODUCT':
			return { ...state, calculateRate:action.payload}; 
																			
		default:
			return state
	}
}
export default settings