import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {IntlProvider} from 'react-intl'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import Routes from './routing/Routes'
import { connect } from 'react-redux';
import allMessages from '../_metronic/i18n/i18nProvider'
import {getTcmbData} from  '../redux/actions'
// type Props = {
//   basename: string
// }
  class App extends React.Component  {
    constructor(props) {
      super(props);
      this.state = {
        visibility: true,
      };
    }
    //******************************************************************************************************************************************************************/
    componentDidMount(){
      
      
      //store.dispatch(wsConnect(String(process.env.REACT_APP_SERVER_URL).replace("https", "wss").replace("http", "ws") + "/ws"));
      !this.props.tcmb &&  this.props.dispatch(getTcmbData());
    }
    render(){
      const locale = this.props.locale;
      const messages = allMessages[locale];
      return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={this.props.basename}>
              <IntlProvider locale={locale} messages={messages}>
                <LayoutProvider>
                    <Routes />
                </LayoutProvider>
              </IntlProvider>
            </BrowserRouter>
        </Suspense>
      )
    }
}

const mapStateToProps = state => ({
  locale:state.settings.locale
})


export default connect(mapStateToProps)(App)
