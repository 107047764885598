import {
	PRODUCT_DETAILS_ACTION,
	PRODUCT_DETAILS_SUCCESS,
	PRODUCT_DETAILS_ERROR,
	CREATE_PRODUCT_ACTION,
	CREATE_PRODUCT_SUCCESS,
	CREATE_PRODUCT_ERROR,
	UPDATE_PRODUCT_ACTION,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT_FAILURE,
	DELETE_PRODUCT_ACTION,
	DELETE_PRODUCT_SUCCESS,
	DELETE_PRODUCT_FAILURE
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:true
};

const productDetails  = (state = INIT_STATE, action) => {
	switch (action.type) {
		case PRODUCT_DETAILS_ACTION: //GET OFFER DETAILS
			return { ...state, loading: true };
		case PRODUCT_DETAILS_SUCCESS:
            return { ...state,
                 loading: false,  
                 data: action.response};
		case PRODUCT_DETAILS_ERROR:
            return { ...state, loading: false };	
		case CREATE_PRODUCT_ACTION: //CREATE OFFER
			return { ...state, loading: true };
		case CREATE_PRODUCT_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_PRODUCT_ERROR:
			return { ...state, loading: false,data:{},status:action.response };	
		case UPDATE_PRODUCT_ACTION: //UPDATE OFFER
			return { ...state, loading: true };
		case UPDATE_PRODUCT_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_PRODUCT_FAILURE:
			return { ...state, loading: false,status:action.response }; 
		case DELETE_PRODUCT_ACTION: //UPDATE OFFER
			return { ...state, loading: true };
		case DELETE_PRODUCT_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_PRODUCT_FAILURE:
			return { ...state, loading: false };
		default: return state;
	}
}
export default productDetails
