import {
	DELIVERY_DETAILS_ACTION,
	DELIVERY_DETAILS_SUCCESS,
    DELIVERY_DETAILS_ERROR,
	CREATE_DELIVERY_ACTION,
	CREATE_DELIVERY_SUCCESS, 
	CREATE_DELIVERY_ERROR,
	UPDATE_DELIVERY_ACTION,
	UPDATE_DELIVERY_SUCCESS, 
	UPDATE_DELIVERY_ERROR,
	DELETE_DELIVERY_ACTION,
	DELETE_DELIVERY_SUCCESS,
	DELETE_DELIVERY_FAILURE
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:true
};

const deliveriedDetail = (state = INIT_STATE, action) => {
	switch (action.type) {
		case DELIVERY_DETAILS_ACTION: //GET DELIVERY DETAILS
			return { ...state, loading: true };
		case DELIVERY_DETAILS_SUCCESS:
            return { ...state,
                 loading: false,  
                 data: action.response};
		case DELIVERY_DETAILS_ERROR:
            return { ...state, loading: false };	
		case UPDATE_DELIVERY_ACTION: //UPDATE
			return { ...state,loading: false};
		case UPDATE_DELIVERY_SUCCESS:
			return { ...state, loading: true, data: action.response };
		case UPDATE_DELIVERY_ERROR:
			return { ...state, loading: true, status:action.response};
		case CREATE_DELIVERY_ACTION: //CREATE DELIVERY
			return { ...state, loading: true };
		case CREATE_DELIVERY_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_DELIVERY_ERROR:
			return { ...state, loading: false, data: action.response  };	
		case DELETE_DELIVERY_ACTION: //DELETE DELIVERY
			return { ...state, loading: true };
		case DELETE_DELIVERY_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_DELIVERY_FAILURE:
			return { ...state, loading: false };
		default: return state;
	}
}
export default deliveriedDetail
