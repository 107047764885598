import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {MenuTestPage} from '../pages/MenuTestPage'

import {MasterLayout} from '../../_metronic/layout/MasterLayout'

import PriceList from '../reports/pricelist'


export function PrivateRoutes() {

  const Generals = lazy(() => import('../pages/generals'))
  const Products = lazy(() => import('../pages/products'))
  const Offers = lazy(() => import('../pages/offers'))

  const Offer = lazy(() => import('../reports/OfferT1'))





  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (

    <Suspense fallback={<FallbackView />}>
      <Switch>

      <Route path='/reports/pricelist' component={PriceList}/>
        <Route path='/reports/offer/:id' component={Offer}/>

        
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />




        <MasterLayout>
          <Route path='/dashboard' component={DashboardWrapper} />
          <Route path='/generals' component={Generals} />
          <Route path='/products' component={Products} />
          <Route path='/offers' component={Offers} />
        </MasterLayout>
        


        <Redirect from='/auth' to='/dashboard'  />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='/error/404' />





      </Switch>
    </Suspense>
  )
}
