import {
	AK_USER_LOGIN_ACTION,
	AK_USER_LOGIN_SUCCESS,
    AK_USER_LOGIN_FAILURE, 
	AK_USER_LOGOUT_ACTION, 
	AK_USER_REAUTH_ACTION,
	AK_USER_REAUTH_SUCCESS,
	AK_USER_REAUTH_FAILURE
} from '../actions';

const INIT_STATE = {
    akemail: localStorage.getItem('user_id'),
    aktoken: localStorage.getItem('user_token'),
    akuser: localStorage.getItem('user_name'),
    akforgotUserMail: '',
    aknewPassword: '',
    akresetPasswordCode: '',
    akloading: false,
    akerror: ''
};

const  akaysisauth =  (state = INIT_STATE, action) => {
    switch (action.type) {
        case AK_USER_LOGIN_ACTION:
            return {
				akemail: action.email,
				akstatus: "pending",
			};
        case AK_USER_LOGIN_SUCCESS:
            localStorage.setItem('user_id', action.response.data.email);
            localStorage.setItem('user_token', action.response.data.token);
            localStorage.setItem('user_name', action.response.data.name);
            return {
				akstatus: "successful",
				akemail: action.response.data.email,
				aktoken: action.response.data.token,
				akuser: action.response.data.name
            };

        case AK_USER_LOGIN_FAILURE:
            return {
				akstatus: "failed",
                explanation: action.response.data.explanation ? action.response.data.explanation : "NO_RESPONSE"
                
			};
        case AK_USER_REAUTH_ACTION:
			return {
				...state,
				reauthing: true
			}        
        case AK_USER_REAUTH_SUCCESS:
			return {
				...state,
				akstatus: "successful",
				akreauthing: null,
				akemail: action.response.email,
				akname: action.response.name,
            }
        case AK_USER_REAUTH_FAILURE:
            localStorage.removeItem('user_token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_name');
			return {
                ...state,
				akreauthing: null,
				akstatus: "failed",
                explanation: "Re-Authing Failed",
                akemail : "",
                akname : ""
			}


        case AK_USER_LOGOUT_ACTION:
            localStorage.removeItem('user_token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_name');
            return {
				akstatus: "",
				aktoken: "",
                akemail: "",
                akuser:""
			}
        default: return { ...state };
    }
}
export default akaysisauth
