import React, { Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer';

import { connect } from "react-redux";
import PriceListT1 from "./PriceList/pricelistT1";
import { getCompany,getProductList } from '../../redux/actions';

class PDFViewerModal extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        loading: true,
        selectedOrderOption:"urun_adi"
      };
    }

    componentDidMount() {
  
      !this.props.productList.data.length &&this.props.dispatch(getProductList("details","model_category"));
    
    };


    render() {     
        const {data} = this.props.productList;

        const {productsFilter,listPerVal,pricelisttype,pricelisttext,priceListTextOpen} = this.props.settings;
        const priceListText = pricelisttext.replace("%XX",("%"+listPerVal))
        const company = this.props.company.data;
        const {locale} =  this.props;
        
        const Lang = locale === "tr" ?  0 : 1 ;
        console.log("geldi",Lang)
        return !this.props.productList.loading && !this.props.company.loading  ? (
            <Fragment>
              { data && company  ?                
              <PDFViewer  style={{height: '100vh',  width: '100vw',top: 0,left: 0}}>
                {true?
                  <PriceListT1 data={data} company={company} oran={listPerVal} kategori={productsFilter} pricelisttext={priceListText} Lang={Lang} priceListTextOpen={priceListTextOpen} user={this.props.auth.user}/>
                :
                null}
              </PDFViewer>:<div className="loading" />}       
            </Fragment>
        ) : (
            <div className="loading" />
        );
    }
}
const mapStateToProps = state  => ({
    settings:state.settings,
    productList:state.productList,
    locale:state.settings.locale,
    modelList:state.modelList,
    auth:state.auth,
    company: state.myCompany
});
export default connect (mapStateToProps)(PDFViewerModal);
