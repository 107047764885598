/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import {Redirect, Router,Switch, Route} from 'react-router-dom'
import {connect} from 'react-redux'

import {PrivateRoutes} from './PrivateRoutes'
import {Logout,AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import { createBrowserHistory } from 'history';


const appHistory = createBrowserHistory();



class Routes extends React.Component {
  constructor(props) {
		super(props);

		this.state = {
			visibility: true,
		};
	}

  
render() {
  const isAuthorized =  this.props.auth.status==="successful" ? true : false;
  let {reauthing} = this.props.auth;


  if(reauthing){
    return(
      <div>

      </div>
    )}
    else{
      return (

        <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/' />
        )}
  
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
  
        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
     
            <PrivateRoutes />
  
        )}
      </Switch>







        // <Router history={appHistory}>

        //     {isAuthorized ? 

        //           <PrivateRoutes />
             
        //     :<Redirect to={{ pathname: "/auth/login" }} />}

        //     <Route exact path="/auth/login" render={() => {
        //         return this.props.auth !== "successful" ?
        //           <AuthPage /> :
        //           <Redirect to={{ pathname: "/dashboard" }} />
        //       }} />	



        //       <Route path='/error' component={ErrorsPage} />
        //       <Route path='/logout' component={Logout} />
        // </Router>






              
   
      )
    }
  }
}

const mapStateToProps = state => ({
	auth:state.auth,
})


export default connect(mapStateToProps)(Routes)

