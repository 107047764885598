import {
	IMAGE_DETAILS_ACTION,
	IMAGE_DETAILS_SUCCESS,
	IMAGE_DETAILS_ERROR,
	CREATE_IMAGE_ACTION,
	CREATE_IMAGE_SUCCESS,
	CREATE_IMAGE_ERROR,
	UPDATE_IMAGE_ACTION,
	UPDATE_IMAGE_SUCCESS,
	UPDATE_IMAGE_FAILURE,
	DELETE_IMAGE_ACTION,
	DELETE_IMAGE_SUCCESS,
	DELETE_IMAGE_FAILURE
} from '../actions';

const INIT_STATE = {
	data: {},
	loading:true
};

const imageDetails = (state = INIT_STATE, action) => {
	switch (action.type) {
		case IMAGE_DETAILS_ACTION: //GET OFFER DETAILS
			return { ...state, loading: true };
		case IMAGE_DETAILS_SUCCESS:
            return { ...state,
                 loading: false,  
                 data: action.response};
		case IMAGE_DETAILS_ERROR:
            return { ...state, loading: false };	
            	
		case CREATE_IMAGE_ACTION: //CREATE MODEL
			return { ...state, loading: true };
		case CREATE_IMAGE_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case CREATE_IMAGE_ERROR:
			return { ...state, loading: false,data:{},status:action.response };	
		case UPDATE_IMAGE_ACTION: //UPDATE MODEL
			return { ...state, loading: true };
		case UPDATE_IMAGE_SUCCESS:
			return { ...state, loading: false, data: action.response };
		case UPDATE_IMAGE_FAILURE:
			return { ...state, loading: false ,status:action.response};
		case DELETE_IMAGE_ACTION: //DELETE MODEL
			return { ...state, loading: true };
		case DELETE_IMAGE_SUCCESS:
			return { ...state, loading: false,  };
		case DELETE_IMAGE_FAILURE:
			return { ...state, loading: false };
		default: return state;
	}
}
export default imageDetails
